<template>
    <div>
        <label v-if="label" :for="label" class="block text-xs sm:text-sm font-medium leading-5" :class="theme === 'dark' ? 'text-white' : 'text-gray-900' ">{{ label }}</label>
        <div class="mt-1 relative rounded-md shadow-sm" :class="[{'border-gray-400 border': border === 'enable'} , theme === 'dark' ? 'bg-scorelitgray text-gray-300' : 'bg-white text-black' ]">
            <span :class="inputClasses">
                <input ref="input_d" maxlength="2" class="w-8 ml-1 sm:ml-3 py-2 sm:py-4 px-1" :class="[inputClasses, {'bg-scorelitgray text-gray-300': theme === 'dark'}]" placeholder="dd" :id="label" type="text" v-model="date.day" @input="reactOnInput" :aria-invalid="hasErrors ? 'true' : 'false'">
                <span class="text-gray-500">/</span>
                <input ref="input_m" maxlength="2" class="w-10 ml-2 py-2 sm:py-4 px-1" :class="[inputClasses, {'bg-scorelitgray text-gray-300': theme === 'dark'}]" placeholder="mm" type="text" v-model="date.month"  @input="reactOnInput" :aria-invalid="hasErrors ? 'true' : 'false'">
                <span class="text-gray-500">/</span>
                <input ref="input_y" maxlength="4" class="w-10 ml-2 py-2 sm:py-4 px-1" :class="[inputClasses, {'bg-scorelitgray text-gray-300': theme === 'dark'}]" placeholder="jjjj" type="text" v-model="date.year" @input="reactOnInput" :aria-invalid="hasErrors ? 'true' : 'false'">
                <!-- v-on:keyup="moveField('input_y')" -->
            </span>
            <div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-medium">{{ error }}</p>
        </template>
    </div>
</template>

<script>
export default {
    name: "DateInput",
    props: ['value', 'errors', 'label', 'theme', 'border'],
    data() {
        return {
            date: {
                day: "",
                month: "",
                year: ""
            }
        };
    },
    created(){
        this.date = this.parseValue(this.value);
    },
    watch: {
        outputDate: function (date) {
            this.$emit('update:value', date)
        }
    },
    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
        inputClasses: function () {
            if (this.hasErrors){
                return "form-input pr-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red text-xs sm:text-sm sm:leading-5";
            } else {
                return "form-input text-xs sm:text-sm sm:leading-5";
            }
        },
        outputDate: function () {
            const datetime = new Date().toISOString()
            const currentTime = datetime.substring(datetime.indexOf('T'))

            const year = this.date.year
            const month = this.date.month.toString().padStart(2, '0') 
            const day = this.date.day.toString().padStart(2, '0')

            return year + '-' + month + '-' + day + currentTime
        }
    },
    methods:{
        parseValue: function(value){
            const date = new Date(value);

            const year = !isNaN(date.getFullYear()) ? date.getFullYear() : "";
            const month = !isNaN(date.getMonth()) ? date.getMonth() + 1 : "";
            const day = !isNaN(date.getDate()) ? date.getDate() : "";

            return { year, month, day };
        },
        reactOnInput: function (event){
            this.clearErrors();
            this.focusField(event.target)
        },
        clearErrors(){
            this.$emit('update:errors', []);
        }, 
        focusField(target){
            const max_length = parseInt(target.attributes["maxlength"].value);
            const length = target.value.length;

            if(length >= max_length && target.nextSibling !== null && target.nextSibling.nextSibling !== null){
                target.nextSibling.nextSibling.focus();
            } 
            // else if (length === 0 && target.previousSibling !== null && target.previousSibling.previousSibling !== null){
            //     target.previousSibling.previousSibling.focus();
            // }
        }
    }
}
</script>

<style scoped>
    .input-wrapper{
        vertical-align: middle;
    }
</style>
