<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    <i18n-t keypath="account.title" />
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 lg:gap-x-32 gap-y-8 md:gap-y-12 mt-4 md:mt-6">
                    <div class="w-full">
                        <FormSection :title="$t('account.personal.title')" :hasSaveButton="true" @submit="sendForm1">
                            <div class="grid grid-cols-2 gap-x-6">
                                <TextInput v-model:value="form1.first_name" v-model:errors="errors1.first_name" :label="$t('account.personal.first_name.title')" :placeholder="$t('account.personal.first_name.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                                <TextInput v-model:value="form1.last_name" v-model:errors="errors1.last_name" :label="$t('account.personal.last_name.title')" :placeholder="$t('account.personal.last_name.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                            </div>
                            <DateInput v-model:value="form1.birth_day" v-model:errors="errors1.birth_day" :label="$t('account.personal.birth_day')" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div>

                    <div class="w-full">
                        <FormSection :title="$t('account.email.title')" :hasSaveButton="true" @submit="sendForm2">
                            <TextInput :disabled="true" v-model:value="form2.email" v-model:errors="errors2.email" :label="$t('account.email.email.title')" :placeholder="$t('account.email.email.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                            <!-- <TextInput :disabled="true" v-model:value="form2.email_confirmation" v-model:errors="errors2.email_confirmation" :label="$t('account.email.confirmation.title')" :placeholder="$t('account.email.confirmation.placeholder')" theme="dark" class="mt-4 md:mt-6" /> -->
                        </FormSection>
                    </div>
                    
                    <div class="w-full">
                        <FormSection :title="$t('account.password.title')" :hasSaveButton="true" @submit="sendForm3">
                            <TextInput password v-model:value="form3.current_password" v-model:errors="errors3.current_password" :label="$t('account.password.current.title')" :placeholder="$t('account.password.current.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                            <TextInput password v-model:value="form3.password" v-model:errors="errors3.password" :label="$t('account.password.password.title')" :placeholder="$t('account.password.password.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                            <TextInput password v-model:value="form3.password_confirmation" v-model:errors="errors3.password_confirmation" :label="$t('account.password.confirmation.title')" :placeholder="$t('account.password.confirmation.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div>
                    
                    <div class="w-full">
                        <FormSection :title="$t('account.banking.title')" :hasSaveButton="true" @submit="sendForm4">
                            <div class="grid grid-cols-2 gap-x-6">
                                <TextInput v-model:value="form4.street"   v-model:errors="errors4.street" :label="$t('account.location.street.title')" :placeholder="$t('account.location.street.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                                <TextInput v-model:value="form4.city"     v-model:errors="errors4.city" :label="$t('account.location.city.title')" :placeholder="$t('account.location.city.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                                <TextInput v-model:value="form4.province" v-model:errors="errors4.province" :label="$t('account.location.province.title')" :placeholder="$t('account.location.province.placeholder')"  theme="dark" class="mt-4 md:mt-6" />
                                <TextInput v-model:value="form4.zip"      v-model:errors="errors4.zip" :label="$t('account.location.zip.title')" :placeholder="$t('account.location.zip.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                            </div> 
                            <TextInput v-model:value="form4.country"  v-model:errors="errors4.country" :label="$t('account.location.country.title')" :placeholder="$t('account.location.country.placeholder')" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div>

                    <div class="w-full">
                        <FormSection title="Taal / Language" :hasSaveButton="true" @submit="sendForm5">
                            <QuestionWithOptions v-model:value="form5.locale" v-model:errors="errors5.locale" :question_container="{ options: [{ id: 'nl-NL', name: 'Nederlands' }, {id: 'en-GB', name: 'English'}]}" theme="dark" class="mt-4 md:mt-6" />
                        </FormSection>
                    </div>
                </div>
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import { update_profile, update_password, update_email } from "@/helper/api/EditProfileHelper";
import DashboardWrapper from "@/wrappers/Dashboard";
import FormSection from "@/wrappers/partials/FormSection";
import TextInput from "@/components/forms/TextInput";
import DateInput from "@/components/forms/DateInput";
import QuestionWithOptions from "@/components/forms/QuestionWithOptions";
import messages from "@/helper/store/messageHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    name: "Account",
    components: {
        DashboardWrapper,
        FormSection,
        TextInput,
        DateInput,
        QuestionWithOptions,
    },
    methods:{
        sendForm1 () {
            update_profile(this.form1)
                .then(()=>{
                    messages.success($t('account.personal.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors1 = data.errors;
                });
        },
        sendForm2 () {
            update_email(this.form2.email, this.form2.email_confirmation)
                .then(()=>{
                    messages.success($t('account.email.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors2 = data.errors;
                });
        },
        sendForm3 () {
            update_password(this.form3.current_password, this.form3.password, this.form3.password_confirmation)
                .then(()=>{
                    messages.success($t('account.password.success'));
                    this.form3.password = "";
                    this.form3.password_confirmation = "";
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors3 = data.errors;
                })
                .finally(() => {
                    this.form3.current_password = "";
                });
        },
        sendForm4 () {
            update_profile(this.form4)
                .then(()=>{
                    messages.success($t('account.banking.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors4 = data.errors;
                });
        },
        sendForm5() {
            update_profile(this.form5)
                .then(()=>{
                    messages.success($t('account.language.success'));
                })
                .catch((data)=>{
                    messages.error(data.message);
                    this.errors5 = data.errors;
                });
        },
        
        setFormFields() {
            const user = this.$store.getters['getUser'];

            for( const p in this.form1){
                this.form1[p] = (p in user ? user : this.form1)[p];
            }
            for( const p in this.form2){
                this.form2[p] = (p in user ? user : this.form2)[p];
            }
            for( const p in this.form3){
                this.form3[p] = (p in user ? user : this.form3)[p];
            }
            for( const p in this.form4){
                this.form4[p] = (p in user ? user : this.form4)[p];
            }
            for( const p in this.form5){
                this.form5[p] = (p in user ? user : this.form5)[p];
            }
            //this.current_user_image = user.thumbnail;
        },
    },
    created(){
        this.setFormFields();
    },
   
    data() {
        return {
            // current_user_image: '',
            form1: {
                first_name: '',
                last_name: '',
                birth_day: '',
            },
            form2: {
                email: '',
                email_confirmation: '',
            },
            form3: {
                current_password: '',
                password: '',
                password_confirmation: '',
            },
            form4: {
                street: '',
                city: '',
                province: '',
                zip: '',
                country: '',
            },
            form5: {
                locale: i18n.global.locale || 'nl-NL',
            },
            errors1: {
                first_name: [],
                last_name: [],
                birth_day: [],
            },
            errors2: {
                email: [],
                email_confirmation: [],
            },
            errors3: {
                current_password: [],
                password: [],
                password_confirmation: [],
            },
            errors4: {
                street: [],
                city: [],
                province: [],
                zip: [],
                country: [],
                iban: [],
                bic: [],
            },
            errors5: {
                locale: [],
            },
        }
    },
}
</script>

<style scoped>
</style>
